import {
  CreateBannerRequestInterface,
  UpdateBannerImageRequestInterface,
  UpdateBannerRequestInterface,
} from '@/data/payload/contracts/BannerRequest'

export class CreateBannerRequest implements CreateBannerRequestInterface {
  public bannerName?: string
  public bannerDescription?: string
  public bannerImage?: Blob
  public targetUrl?: string
  public bannerCategory?: string
  public bannerType?: string
  public startDate?: string
  public endDate?: string
  public bannerOrder?: string

  constructor(
    bannerName?: string,
    bannerDescription?: string,
    bannerImage?: Blob,
    targetUrl?: string,
    bannerCategory?: string,
    bannerType?: string,
    startDate?: string,
    endDate?: string,
    bannerOrder?: string
  ) {
    this.bannerName = bannerName
    this.bannerDescription = bannerDescription
    this.bannerImage = bannerImage
    this.targetUrl = targetUrl
    this.bannerCategory = bannerCategory
    this.bannerType = bannerType
    this.startDate = startDate
    this.endDate = endDate
    this.bannerOrder = bannerOrder
  }

  public toPayload() {
    // Specific for form data, this toPayload() will actually convert them into FormData
    const data = new FormData()
    data.append('banner_name', this.bannerName as string)
    data.append('banner_description', this.bannerDescription as string)
    data.append('banner_image', this.bannerImage as Blob)
    data.append('target_url', this.targetUrl as string)
    data.append('banner_category', this.bannerCategory as string)
    data.append('banner_type', this.bannerType as string)
    data.append('start_date', this.startDate as string)
    data.append('end_date', this.endDate as string)
    data.append('banner_order', this.bannerOrder as string)

    return data
  }
}

export class UpdateBannerRequest implements UpdateBannerRequestInterface {
  public bannerName?: string
  public bannerDescription?: string
  public targetUrl?: string
  public bannerCategory?: string
  public bannerType?: string
  public startDate?: string
  public endDate?: string
  public bannerOrder?: string

  constructor(
    bannerName?: string,
    bannerDescription?: string,
    targetUrl?: string,
    bannerCategory?: string,
    bannerType?: string,
    startDate?: string,
    endDate?: string,
    bannerOrder?: string
  ) {
    this.bannerName = bannerName
    this.bannerDescription = bannerDescription
    this.targetUrl = targetUrl
    this.bannerCategory = bannerCategory
    this.bannerType = bannerType
    this.startDate = startDate
    this.endDate = endDate
    this.bannerOrder = bannerOrder
  }

  public toPayload() {
    const data = {
      banner_name: this.bannerName,
      banner_description: this.bannerDescription,
      target_url: this.targetUrl,
      banner_category: this.bannerCategory,
      banner_type: this.bannerType,
      start_date: this.startDate,
      end_date: this.endDate,
      banner_order: this.bannerOrder,
    }

    return JSON.stringify(data)
  }
}

export class UpdateBannerImageRequest
  implements UpdateBannerImageRequestInterface {
  public bannerImage?: Blob

  constructor(bannerImage?: Blob) {
    this.bannerImage = bannerImage
  }

  public toPayload() {
    // Specific for form data, this toPayload() will actually convert them into FormData
    const data = new FormData()

    data.append('banner_image', this.bannerImage as Blob)

    return data
  }
}
