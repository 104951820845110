





















import { Component, Prop, Vue } from 'vue-property-decorator'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'

@Component({
  inheritAttrs: false,
  components: { DropdownSelect },
})
export default class BannerDropdown extends Vue {
  @Prop({ required: true }) private fieldLabel!: string
  @Prop() private fieldDescription!: string
  @Prop() private errorMessage!: string
  @Prop({ default: false }) private required!: boolean
  @Prop({ required: false, default: 'max-w-68-a'}) private width!: string
}
