




















































































































































































import dayjs from 'dayjs'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  required,
  maxLength,
  url as urlValidation,
  or,
  and,
} from 'vuelidate/lib/validators'
import controller, { OptionsNumber, OptionsString } from '@/app/ui/controllers/BannerController'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import BannerTextInput from '../../components/BannerTextInput/index.vue'
import BannerDropdown from '../../components/BannerDropdown/index.vue'
import BannerDatePicker from '../../components/BannerDatePicker/index.vue'
import ImageUpload from '@/app/ui/components/ImageUpload/index.vue'
import { Banner, BannerType } from '@/domain/entities/Banner'

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    Modal,
    BannerTextInput,
    BannerDropdown,
    BannerDatePicker,
    LoadingOverlay,
    ImageUpload
  },
})
export default class EditBanner extends Vue {
  controller = controller
  maxCount = 30
  MAX_SIZE = 5000
  form = {
    type: [] as Record<string, any>,
    title: '',
    description: '',
    bannerImage: <File[] | Array<undefined>>[],
    url: '',
    order: [] as Record<string, any>,
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
  }
  isImageFromFile = false
  isImageValid = false
  successModal = false
  confirmationModal = false
  todayDate = dayjs().format('YYYY-MM-DD')

  created(): void {
    controller.getBannerTypes()
    controller.getOrderBanner()

    controller.setLoading(true)

    EventBus.$on(EventBusConstants.SAVE_BANNER_SUCCESS, () => {
      this.successModal = true
    })
  }

  @Watch('bannerDetail')
  onBannerDetailChanged(val: Banner): void {
    this.form = {
      type:
        controller.bannerType
          .map((item) => ({ label: item.id, value: item.id }))
          .find((item) => item.value === val.type) || [],
      title: val.title || '',
      description: val.description || '',
      bannerImage: [new File([], <string>val.imageUrl)],
      url: val.url || '',
      order:
        controller.constants.order.find((item) => item.value === val.order) ||
        [],
      startDate: val.startDate
        ? dayjs(val.startDate).format('YYYY-MM-DD')
        : '',
      endDate: val.endDate
        ? dayjs(val.endDate).format('YYYY-MM-DD')
        : '',
      startTime: val.startDate
        ? dayjs(val.startDate).format('HH:mm:ss')
        : '',
      endTime: val.endDate
        ? dayjs(val.endDate).format('HH:mm:ss')
        : '',
    }
  }

  @Watch('bannerType')
  onBannerTypeChanged(val: BannerType[]): void {
    if (val.length > 0) {
      controller.getBannerDetail(this.$route.params.id as string)
    }
  }

  @Validations()
  validations(): Record<string, unknown> {
    return {
      form: {
        type: { required },
        title: { required, maxLength: maxLength(70) },
        description: { maxLength: maxLength(50) },
        url: {
          url: and(
            required,
            or(urlValidation, (value: string) =>
              value.startsWith('lionparcel://app')
            )
          ),
        },
        order: { required },
        startDate: { required },
        endDate: { required },
        startTime: { required },
        endTime: { required },
      },
    }
  }

  get bannerDetail(): Banner {
    return controller.bannerDetail
  }

  get bannerType(): BannerType[] {
    return controller.bannerType
  }

  get orderOptions(): OptionsNumber[] {
    return controller.constants.order
  }

  get bannerSource(): string[] {
    if (this.isImageFromFile && this.form.bannerImage.length > 0) {
      return [URL.createObjectURL(this.form.bannerImage[0])]
    } else if (this.form.bannerImage[0]) {
      return [this.form.bannerImage[0].name]
    } else {
      return []
    }
  }

  get typeOptions(): OptionsString[] {
    const types = []
    if (controller.bannerType.length > 0) {
      // standard loop is performance wise
      for (let i = 0; i < controller.bannerType.length; i++) {
        types.push({
          label: controller.bannerType[i].id,
          value: controller.bannerType[i].id,
        })
      }
    }
    return types
  }

  get isActiveBanner(): boolean {
    return controller.bannerDetail.status === 'Active'
  }

  get isExpiredOrCancelled(): boolean {
    return ['Expired', 'Cancelled'].includes(controller.bannerDetail.status || '')
  }

  private errorMessage(
    error: boolean,
    message: string,
    error2?: boolean,
    message2?: string
  ): string | undefined {
    if (error) {
      return message
    } else if (error2) {
      return message2
    } else {
      return undefined
    }
  }

  private onFileChange($event: File) {
    this.form.bannerImage.splice(0, 1, $event)
    if ($event) {
      this.isImageValid = true
      this.isImageFromFile = true
    } else {
      this.isImageFromFile = false
      this.isImageValid = false
    }
  }

  private onRemoveImage(index: number) {
    this.isImageValid = false
    this.form.bannerImage.splice(index, 1)
  }

  private onEditBanner() {
    if (
      !this.$v.form.$invalid &&
      (!this.isImageFromFile || this.isImageValid)
    ) {
      controller.updateBanner({ id: this.$route.params.id, ...this.form })
      if (this.isImageFromFile && this.form.bannerImage[0]) {
        const payload = {
          id: this.$route.params.id,
          bannerImage: this.form.bannerImage[0]
        }
        controller.updateBannerImage(payload)
      }
      this.confirmationModal = false
    } else {
      Vue.notify({
        title: 'Edit Banner',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  private onCloseSuccessModal() {
    this.successModal = false
    this.$router.push({
      name: 'BannerDetailPage',
      params: { id: this.$route.params.id },
    })
    controller.getBannerDetail(this.$route.params.id)
  }

  private formatDate(date: string) {
    return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm Z')
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.SAVE_BANNER_SUCCESS)
  }
}
