import { container } from 'tsyringe'
import Vue from 'vue'
import dayjs from 'dayjs'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { Banner, Banners, BannerType, OrderBanner } from '@/domain/entities/Banner'
import { Pagination } from '@/domain/entities/Pagination'
import { BannerPresenter } from '@/app/ui/presenters/BannerPresenter'
import { Utils, EventBus, EventBusConstants } from '@/app/infrastructures/misc'
import {
  CreateBannerRequest,
  UpdateBannerRequest,
  UpdateBannerImageRequest,
} from '@/data/payload/api/BannerRequest'
import { BANNER_PAGINATION } from '@/app/infrastructures/misc/Constants/index'

export interface OptionsNumber {
  label: string
  value: number
}

export interface OptionsString {
  label?: string
  value?: string
}

export interface BannerState {
  constants: {
    order: OptionsNumber[]
  }
  isLoading: boolean
  isLoadingTypes: boolean
  isUploading: boolean
  bannerData: Banner[]
  paginationData: Pagination
  bannerType: BannerType[]
  bannerDetail: Banner
  orderBanner: OrderBanner
}

@Module({ namespaced: true, store, name: 'banner', dynamic: true })
class BannerController extends VuexModule implements BannerState {
  private presenter: BannerPresenter = container.resolve(BannerPresenter)
  public constants = {
    order: [] as OptionsNumber[]
  }
  public isLoading = false
  public isLoadingTypes = false
  public isUploading = false
  public bannerData = [new Banner()]
  public paginationData = new Pagination(1, BANNER_PAGINATION, 0)
  public bannerType = [new BannerType()]
  public bannerDetail = new Banner()
  public orderBanner = new OrderBanner()

  @Action({ rawError: true })
  public createBanner(form: {
    type: Record<string, any>
    title: string
    description: string
    bannerImage: File
    url: string
    order: Record<string, any>
    startDate: string
    endDate: string
    startTime: string
    endTime: string
  }) {
    this.setLoading(true)
    const payload = new CreateBannerRequest(
      form.title,
      form.description,
      form.bannerImage,
      form.url,
      'SDRHP',
      form.type.value,
      dayjs(
        `${form.startDate} ${form.startTime}`,
        'YYYY-MM-DD HH:mm:ss'
      ).format(),
      dayjs(`${form.endDate} ${form.endTime}`, 'YYYY-MM-DD HH:mm:ss').format(),
      form.order.value
    )

    this.presenter
      .create(payload)
      .then(() => {
        EventBus.$emit(EventBusConstants.SAVE_BANNER_SUCCESS, {})
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Banner Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateBanner(form: {
    id: string
    type: Record<string, any>
    title: string
    description: string
    url: string
    order: Record<string, any>
    startDate: string
    endDate: string
    startTime: string
    endTime: string
  }) {
    this.setLoading(true)
    const payload = new UpdateBannerRequest(
      form.title,
      form.description,
      form.url,
      'SDRHP',
      form.type.value,
      dayjs(
        `${form.startDate} ${form.startTime}`,
        'YYYY-MM-DD HH:mm:ss'
      ).format(),
      dayjs(`${form.endDate} ${form.endTime}`, 'YYYY-MM-DD HH:mm:ss').format(),
      form.order.value
    )

    this.presenter
      .update(form.id, payload)
      .then(() => {
        EventBus.$emit(EventBusConstants.SAVE_BANNER_SUCCESS, {})
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Banner Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateBannerImage(form: { id: string; bannerImage: File }) {
    this.setUploading(true)
    const payload = new UpdateBannerImageRequest(form.bannerImage)

    this.presenter
      .updateImage(form.id, payload)
      .then(() => {
        EventBus.$emit(EventBusConstants.SAVE_BANNER_SUCCESS, {})
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Banner Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setUploading(false)
      })
  }

  @Action({ rawError: true })
  public getBannerTypes() {
    this.setLoadingTypes(true)
    this.presenter
      .getTypes()
      .then(res => {
        this.setBannerType(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Banner Error',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingTypes(false)
      })
  }

  @Action({ rawError: true })
  public getBannerDetail(id: string) {
    this.setLoading(true)
    this.presenter
      .get(id)
      .then(res => {
        this.setBannerDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Banner Error',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getBannerList(params: Record<string, any>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setBannerData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Banner Error',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public cancelBanner(id: string) {
    this.setLoading(true)

    this.presenter
      .delete(id)
      .then(() => {
        EventBus.$emit(EventBusConstants.CANCEL_BANNER_SUCCESS, { id })
      })
      .catch(error => {
        Vue.notify({
          title: 'Cancel Banner Error',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getOrderBanner() {
    this.setLoading(true)

    this.presenter
    .getOrderBanner()
    .then((res) => {
      this.setOrderBanner(res)
    })
    .catch(error => {
      Vue.notify({
        title: 'Cancel Banner Error',
        text:
          error.status === 400 ? error.error.message.en : 'Something wrong',
        type: 'error',
        duration: 5000,
      })
    })
    .finally(() => {
      this.setLoading(false)
    })
  }


  @Mutation
  private setBannerData(banners: Banners) {
    this.paginationData = banners.pagination as Pagination
    this.bannerData = banners.data as Banner[]
  }

  @Mutation
  private setBannerType(bannerTypes: BannerType[]) {
    this.bannerType = bannerTypes
  }

  @Mutation
  private setBannerDetail(banner: Banner) {
    this.bannerDetail = banner
  }

  @Mutation
  public setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingTypes(bool: boolean) {
    this.isLoadingTypes = bool
  }

  @Mutation
  private setOrderBanner(data: OrderBanner) {
    this.orderBanner = data
    const rows = []
    if (data && data.value) {
      for(let i = 1; i <= data.value; i++) {
        const row = { label: `${i}`, value: i }
        rows.push(row)
      }
    }
    this.constants.order = rows
  }

  @Mutation
  private setUploading(bool: boolean) {
    this.isUploading = bool
  }
}

export default getModule(BannerController)
